import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { Formatter } from '@factofly/formatting';
import { TaskStatusEnum } from '~/.generated/globalTypes';
import useUser from '~/contexts/User/useUser';
import CardItem from '~/features/shared/components/CardItem';
import EditButton from '~/features/shared/components/EditButton';
import CardActions from '~/features/cards/components/CardActions';
import CardMenu from '~/features/cards/components/CardMenu';
import { TaskSearchQuery_items_edges_node as TaskNode } from './graphql/.generated/TaskSearchQuery';
import TaskProgressBar from '../TaskProgressBar';
import DeleteButton from '~/features/shared/components/DeleteButton';
import useDeleteTask from '../../hooks/useDeleteTask';
import SearchCardHeader from '~/features/search/components/SearchCardHeader';
import TaskBadges from '../TaskBadges';
import CancelButton from '~/features/shared/components/CancelButton/CancelButton';
import useCancelTask from '../../hooks/useCancelTask';

type TaskSearchCardProps = {
  task: TaskNode;
  showUser?: boolean;
  selectable?: boolean;
  allowContractDownload?: boolean;
  allowDelete?: boolean;
  allowWorkLogDownload?: boolean;
};

export default function TaskSearchCard({
  task,
  showUser,
  allowContractDownload,
  selectable = false,
  allowDelete = false,
  allowWorkLogDownload = false
}: TaskSearchCardProps) {
  const { push } = useRouter();
  const { t } = useTranslation('tasks');
  const { isAdmin, locale } = useUser();
  const { deleteTask } = useDeleteTask();
  const { cancelTask } = useCancelTask();

  const menuVisible = allowContractDownload || allowDelete || allowWorkLogDownload;

  const clickHandler = useCallback(() => {
    if (
      !document.querySelector('a:hover') &&
      !document.querySelector('input:hover') &&
      !document.querySelector('button:hover') &&
      !document.querySelector('.no-click:hover')
    ) {
      push(`/tasks/${task.id}`);
    }
  }, [task.id, push]);

  const keyboardHandler = useCallback(
    (e: any) => {
      if (e.key === 'Enter') {
        clickHandler();
      }
    },
    [clickHandler]
  );

  return (
    <div className="card mb-0" onClick={clickHandler} onKeyDown={keyboardHandler} role="button" tabIndex={0}>
      <SearchCardHeader
        id={task.id}
        title={task.title}
        badge={<TaskBadges className="m-0 ms-1" status={task.status} archived={task.archived} />}
      />
      <div className="card-body p-2 py-1">
        {task.isRetainer && (
          <div>
            <div className="text-end">
              {Formatter.formatDuration(task.timeSpentMinutes, locale, 'long')} /{' '}
              {Formatter.formatDuration(Number(task.expectedHours) * 60, locale, 'long')}
            </div>
            <div>
              <TaskProgressBar progress={(task.timeSpentMinutes / 60 / Number(task.expectedHours)) * 100} />
            </div>
          </div>
        )}
        <div className="row">
          {showUser && (
            <div className="col-sm-2 col-md-3">
              <CardItem label={t('labels.user')} value={task.user.displayName} />
            </div>
          )}
          <div className="col-sm-2 col-md-3">
            <CardItem
              label={t('labels.customerName')}
              value={<Link href={`/customers/${task.customer.id}`}>{task?.customer.name}</Link>}
            />
          </div>
          <div className="col-sm-2 col-md-3">
            <CardItem label={t('labels.startDate')} value={Formatter.formatDate(task.startDate, locale)} />
          </div>
          <div className="col-sm-2 col-md-3">
            <CardItem label={t('labels.endDate')} value={Formatter.formatDate(task.endDate, locale)} />
          </div>
          <div className="col">{selectable && <input type="checkbox" className="form-check-input" />} </div>
          <div className="col">
            <CardActions>
              {(isAdmin ||
                task.status === TaskStatusEnum.DRAFT ||
                task.status === TaskStatusEnum.CHANGES_REQUESTED ||
                task.status === TaskStatusEnum.MORE_INFO_NEEDED) && (
                <EditButton href={`/tasks/${task.id}/edit`} className="btn-sm" showTitle={false} />
              )}
              {menuVisible && (
                <CardMenu>
                  {allowContractDownload && (
                    <Link href={`/api/task-contract/${task.id}`} className="btn">
                      <i className="uil-file-shield-alt me-0" /> {t('buttons.downloadContractAppendix')}
                    </Link>
                  )}
                  {allowWorkLogDownload && (
                    <Link href={`/api/task-work-log/${task.id}`} className="btn">
                      <i className="uil-file-shield-alt me-0" /> {t('buttons.downloadWorkLog')}
                    </Link>
                  )}
                  {allowDelete && <DeleteButton onConfirm={() => deleteTask(task.id)} />}
                  <div className="mt-1">
                    {task.status === TaskStatusEnum.SENT_TO_CUSTOMER_AWAITING_APPROVAL && isAdmin && (
                      <CancelButton onConfirm={() => cancelTask(task.id)} />
                    )}
                  </div>
                </CardMenu>
              )}
            </CardActions>
          </div>
        </div>
      </div>
    </div>
  );
}
