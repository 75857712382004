import { useTranslation } from 'next-i18next';
import CreateButton from '~/features/shared/components/CreateButton';

type CreateCustomerButtonProps = {
  inline?: boolean;
  className?: string;
};

export default function CreateCustomerButton({ inline = false, className }: CreateCustomerButtonProps) {
  const { t } = useTranslation();
  return (
    <CreateButton
      href="/customers/new"
      label={t('buttons.customers.create')}
      className={className}
      inline={inline}
    />
  );
}
