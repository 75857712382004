import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { Formatter } from '@factofly/formatting';

type DashboardCardProps = {
  title: string;
  count: number;
  amount: number;
  badgeClassName: string;
  type: 'invoice' | 'payment';
};

function DashboardCard({ title, count, amount, badgeClassName, type }: DashboardCardProps) {
  const { t } = useTranslation();
  return (
    <div className={classNames('card tilebox-one text-white mb-2', badgeClassName)}>
      <div className="card-body">
        <i className="uil-invoice float-end" />
        <h6 className="text-uppercase mt-0">{title}</h6>
        <h2 className="my-2" id="dashboard-card-amount">
          {Formatter.formatCurrency(amount, 'da', 'dkk')}
        </h2>
        <p className="mb-0">
          <span className="text-nowrap">
            {t(type === 'payment' ? 'labels.payments' : 'labels.invoices', { count })}
          </span>
        </p>
      </div>
    </div>
  );
}

export default DashboardCard;
