import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import InvoiceCardListQuery from './graphql/InvoiceCardListQuery.gql';
import {
  InvoiceOrderByInputArgs,
  InvoiceStatusEnum,
  InvoiceStatusFilter,
  InvoiceWhereInputArgs,
  SortOrder
} from '../../../../.generated/globalTypes';
import {
  InvoiceCardListQuery as InvoicesTableQueryType,
  InvoiceCardListQuery_items_edges_node as InvoiceNode
} from './graphql/.generated/InvoiceCardListQuery';
import CardList from '../../../cards/components/CardList';
import ShowMoreButton from '~/features/search/components/ShowMoreButton';
import InvoiceSearchCard from '../InvoiceSearch/InvoiceSearchCard';

type InvoiceCardListProps = {
  userId?: number;
  showUser?: boolean;
  allowContractDownload?: boolean;
  customerId?: number;
  openInvoicesOnly?: boolean;
};

export default function InvoiceCardList({
  userId = undefined,
  showUser = false,
  allowContractDownload = false,
  customerId = undefined,
  openInvoicesOnly = false
}: InvoiceCardListProps) {
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 6 });
  const [sortBy] = useState<any>([]);
  const [filters] = useState<{ status?: InvoiceStatusEnum; customer?: number }>({});
  const [query] = useState('');

  const showMore = useCallback(() => {
    setPagination((state) => ({
      ...state,
      pageSize: state.pageSize + 6
    }));
  }, []);

  const getOrderBy = useCallback((sort: any) => {
    const orderBy: InvoiceOrderByInputArgs = {};
    if (sort.length >= 1) {
      const key = sort[0].id;

      if (key === 'user') {
        orderBy.user = {
          firstName: sort[0].desc ? SortOrder.desc : SortOrder.asc
        };
      } else if (key === 'customer') {
        orderBy.customer = { name: sort[0].desc ? SortOrder.desc : SortOrder.asc };
      } else {
        orderBy[key as keyof InvoiceOrderByInputArgs] = sort[0].desc ? SortOrder.desc : SortOrder.asc;
      }
    } else {
      orderBy.id = SortOrder.desc;
    }

    return orderBy;
  }, []);

  const getFilter = useCallback(
    (queryString: string, filter: any): InvoiceWhereInputArgs => {
      const filterCustomerId = customerId || filter?.customer;
      const status = filter?.status;

      const statusFilter: InvoiceStatusFilter = {};
      if (status) statusFilter.in = [status];
      if (openInvoicesOnly)
        statusFilter.notIn = [
          InvoiceStatusEnum.CANCELLED,
          InvoiceStatusEnum.SALARY_PAID,
          InvoiceStatusEnum.SALARY_PAID_CUSTOMER_PAID,
          InvoiceStatusEnum.SALARY_PAID_CUSTOMER_NOT_PAID
        ];
      return {
        user: userId ? { id: { equals: userId } } : undefined,
        active: { equals: true },
        query: queryString,
        customer: filterCustomerId ? { id: { equals: filterCustomerId } } : undefined,
        status: status || openInvoicesOnly ? statusFilter : undefined
      };
    },
    [userId, customerId, openInvoicesOnly]
  );

  const { loading, error, data, refetch } = useQuery<InvoicesTableQueryType>(InvoiceCardListQuery, {
    variables: {
      first: pagination.pageSize,
      skip: pagination.pageIndex * pagination.pageSize,
      where: getFilter(query, filters),
      orderBy: getOrderBy(sortBy)
    }
  });

  useEffect(() => {
    refetch({
      first: pagination.pageSize,
      skip: pagination.pageIndex * pagination.pageSize,
      where: getFilter(query, filters),
      orderBy: getOrderBy(sortBy)
    });
  }, [pagination, query, sortBy, filters, getFilter, getOrderBy, refetch]);

  const totalItems = useMemo(() => data?.items?.totalCount || 0, [data]);
  const items = useMemo(() => data?.items?.edges?.map((x) => x.node) || [], [data]);

  const renderItem = useCallback(
    (invoice: InvoiceNode) => (
      <InvoiceSearchCard
        invoice={invoice}
        showUser={showUser}
        allowContractDownload={allowContractDownload}
      />
    ),
    [showUser, allowContractDownload]
  );

  return (
    <>
      <CardList
        className="invoice-card-list mt-2"
        renderItem={renderItem}
        data={items}
        loading={loading}
        totalItems={totalItems}
        pageIndex={pagination.pageIndex}
        pageSize={pagination.pageSize}
        showTotalDisplay={false}
        error={error}
        colClassName="col-md-12"
      />
      <ShowMoreButton
        totalItems={totalItems}
        pageSize={pagination.pageSize}
        showMore={showMore}
        isLoading={loading}
        hasSettled={items.length > pagination.pageSize - 10}
      />
    </>
  );
}
