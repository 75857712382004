/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

type CardMenuProps = {
  /** If true, the menu will be hidden if there is only one child */
  autohide?: boolean;
};

export default function CardMenu({ children, autohide = false }: React.PropsWithChildren<CardMenuProps>) {
  if (autohide && React.Children.count(children) === 1) {
    return children as React.ReactElement;
  }

  return (
    <div className="card-menu ms-1" onClick={(e) => e.stopPropagation()}>
      <Dropdown align="end">
        <Dropdown.Toggle variant="light" size="sm">
          <i className="dripicons-dots-3 me-0" />
        </Dropdown.Toggle>
        <Dropdown.Menu>{children}</Dropdown.Menu>
      </Dropdown>
      <style jsx>{`
        .card-menu {
          display: inline-block;
        }
        .card-menu :global(.dropdown-menu),
        .card-menu :global(.dropdown-item) {
          padding: 0;
          width: 250px;
        }
        .card-menu :global(.dropdown-toggle)::after {
          display: none;
        }
        .card-menu :global(.btn),
        .card-menu :global(a) {
          display: inline-block;
          width: 100%;
          border-radius: 0;
          border: 0;
          outline: 0;
          box-shadow: none;
        }
        .card-menu :global(.btn.btn-primary) {
          background-color: #fff;
          color: #555;
        }
        .card-menu :global(.btn.btn-primary):hover,
        .card-menu :global(a):hover {
          background-color: #f5f5f5;
        }
        .card-menu :global(.ms-2) {
          margin-left: 0 !important;
        }
      `}</style>
    </div>
  );
}
