type TaskProgressBarProps = {
  progress: number;
};

export default function TaskProgressBar({ progress }: TaskProgressBarProps) {
  return (
    <>
      <div className="progress mb-1">
        <div className="progress-bar" style={{ width: `${progress}%` }} />
      </div>
      <style jsx>{`
        .progress {
          height: 10px;
          border-radius: 2px;
        }
        .progress-bar {
          margin-right: 2px;
          height: 15px;
        }
      `}</style>
    </>
  );
}
