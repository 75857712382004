/* eslint-disable react/function-component-definition */
import { NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Dashboard from '~/features/dashboard/components/Dashboard';
import PageHead from '~/features/shared/components/PageHead';

type PageProps = {
  locale: string;
};

const Home: NextPage = () => (
  <div className="container-md">
    <PageHead namespace="common" translationKey="menu.overview" />
    <Dashboard />
  </div>
);

export const getStaticProps = async ({ locale }: PageProps) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common', 'tasks', 'invoices', 'users', 'rewards']))
  }
});

export default Home;
