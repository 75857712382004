import formatDistance from 'date-fns/formatDistance';
import { enGB, da as daDK } from 'date-fns/locale';
export default class Formatter {
    static formatNumber(number, locale, minimumFractionDigits) {
        if (Number.isNaN(number))
            return '';
        return Number(number).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits });
    }
    static formatCurrency(number, locale, currency) {
        const sanitizedNumber = Number.isNaN(number) ? 0 : number;
        const { format } = new Intl.NumberFormat(locale, { style: 'currency', currency });
        return format(sanitizedNumber);
    }
    static formatDuration(minutes, locale, unitDisplay = 'short') {
        const hours = Math.floor(minutes / 60);
        const minutesLeft = minutes % 60;
        const toString = (time, unit) => Intl.NumberFormat(locale, {
            style: 'unit',
            unit,
            unitDisplay
        }).format(time);
        const hoursString = toString(hours, 'hour');
        const minutesString = toString(minutesLeft, 'minute');
        if (hours === 0) {
            return minutesString;
        }
        if (minutesLeft === 0) {
            return hoursString;
        }
        return `${hoursString} ${minutesString}`;
    }
    /**
     * Format date to locale string
     * @param input
     * @param locale
     * @returns
     */
    static formatDate(input, locale, format = 'short', timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone) {
        const date = input instanceof Date ? input : new Date(input);
        return date?.toLocaleDateString(locale, {
            year: format === 'noYear' ? undefined : 'numeric',
            month: ['long', 'noDay', 'noYear'].includes(format) ? 'long' : 'numeric',
            day: format === 'noDay' ? undefined : 'numeric',
            timeZone
        });
    }
    static formatDateTime(input, locale, timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone) {
        const date = input instanceof Date ? input : new Date(input);
        return date?.toLocaleString(locale, { timeZone });
    }
    static formatTime(input, locale, timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone) {
        const date = input instanceof Date ? input : new Date(input);
        return date?.toLocaleTimeString(locale, { timeZone });
    }
    static formatISODate(input) {
        const date = input instanceof Date ? input : new Date(input);
        return date.toISOString().split('T')[0];
    }
    static formatISODateTime(input) {
        const date = input instanceof Date ? input : new Date(input);
        return date.toISOString();
    }
    static formatDateDistance(date, locale, today = new Date()) {
        return formatDistance(date, today, {
            addSuffix: true,
            locale: locale === 'da' || locale === 'da-DK' ? daDK : enGB
        });
    }
}
