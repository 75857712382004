import classNames from 'classnames';
import { InvoiceStatusEnum, TaskStatusEnum } from '~/.generated/globalTypes';
import getInvoiceStatusColor from '~/utils/getInvoiceStatusColor';
import InvoiceBadges from './InvoiceBadges';

type InvoiceStatusBarProps = {
  invoice: {
    status: InvoiceStatusEnum;
    task?: { status?: TaskStatusEnum };
    overdue: boolean;
    earlyPayment: boolean;
  }; showBadge?: boolean;
};

const positions = new Map([
  [InvoiceStatusEnum.DRAFT, 1],
  [InvoiceStatusEnum.SENT, 1],
  [InvoiceStatusEnum.PENDING, 2],
  [InvoiceStatusEnum.SENT_TO_COMPANY, 3],
  [InvoiceStatusEnum.SENT_TO_COMPANY_NEEDS_CONTRACT, 3],
  [InvoiceStatusEnum.SENT_TO_COMPANY_CONTRACT_MADE, 3],
  [InvoiceStatusEnum.SENT_TO_COMPANY_AWAITING_APPROVAL, 3],
  [InvoiceStatusEnum.APPROVED_BY_COMPANY, 3],
  [InvoiceStatusEnum.LATE_PAYMENT, 4],
  [InvoiceStatusEnum.DEBT_COLLECTION, 4],
  [InvoiceStatusEnum.COMPANY_DISPUTE, 4],
  [InvoiceStatusEnum.PAID, 4],
  [InvoiceStatusEnum.PAYMENT_RECEIVED, 4],
  [InvoiceStatusEnum.PAYMENT_ON_HOLD, 4],
  [InvoiceStatusEnum.CANCELLED, 5],
  [InvoiceStatusEnum.DENIED_BY_COMPANY, 5],
  [InvoiceStatusEnum.SALARY_PAID, 5],
  [InvoiceStatusEnum.SALARY_PAID_CUSTOMER_PAID, 5],
  [InvoiceStatusEnum.SALARY_PAID_CUSTOMER_NOT_PAID, 4],
  [InvoiceStatusEnum.MORE_INFO_NEEDED, 3]
]);

export default function InvoiceStatusBar({ invoice, showBadge = false }: InvoiceStatusBarProps) {
  const color = getInvoiceStatusColor(invoice.status);
  const position = positions.get(invoice.status) ?? 5;
  return (
    <div>
      <div className="progress mb-1">
        {position >= 1 && <div className={classNames('progress-bar', color)} style={{ width: '20%' }} />}
        {position >= 2 && <div className={classNames('progress-bar', color)} style={{ width: '20%' }} />}
        {position >= 3 && <div className={classNames('progress-bar', color)} style={{ width: '20%' }} />}
        {position >= 4 && <div className={classNames('progress-bar', color)} style={{ width: '20%' }} />}
        {position >= 5 && <div className={classNames('progress-bar last', color)} style={{ width: '20%' }} />}
      </div>
      {showBadge && (
        <div className="mb-1">
          <InvoiceBadges className="" invoice={{ status: invoice.status, task: { status: invoice.task?.status }, overdue: invoice.overdue, earlyPayment: invoice.earlyPayment }} />
        </div>
      )}
      <style jsx>{`
        .progress {
          height: 10px;
          border-radius: 2px;
        }
        .progress-bar {
          margin-right: 2px;
          height: 15px;
        }
        .progress-bar.last {
          margin-right: 0;
          height: 20px;
        }
      `}</style>
    </div>
  );
}
