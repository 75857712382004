import { useTranslation } from 'next-i18next';
import CreateButton from '~/features/shared/components/CreateButton';

type CreateTaskButtonProps = {
  inline?: boolean;
  hideLabel?: boolean;
  className?: string;
};

export default function CreateTaskButton({
  inline = false,
  hideLabel = false,
  className
}: CreateTaskButtonProps) {
  const { t } = useTranslation();
  return (
    <CreateButton
      href="/tasks/new"
      label={hideLabel ? '' : t('buttons.tasks.create')}
      className={className}
      inline={inline}
    />
  );
}
