import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

type CreateInvoiceButtonProps = {
  className?: string;
};

export default function CreateInvoiceButton({ className }: CreateInvoiceButtonProps) {
  const { t } = useTranslation();
  return (
    <Link href="/invoices/new" className={classNames('btn btn-primary', className)}>
      <i className="uil-plus" />
      {t('buttons.invoices.create')}
    </Link>
  );
}
