import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import useToast from '~/features/shared/hooks/useToast';

import DeleteTaskMutation from './graphql/DeleteTaskMutation.gql';

type DeleteTaskHookArgs = {
  onCompleted?: Function;
};

export default function useDeleteTask({ onCompleted }: DeleteTaskHookArgs = {}) {
  const { toast } = useToast();
  const { t } = useTranslation('tasks');
  const [deleteMutation, { loading: deleting, error: deleteError }] = useMutation(DeleteTaskMutation, {
    refetchQueries: ['TasksTableQuery'],
    onCompleted: () => {
      toast(t('messages.deleted'), { variant: 'success' });
      if (onCompleted) onCompleted();
    },
    onError: () => toast(t('errors.delete'), { variant: 'error' })
  });

  const deleteTask = useCallback(
    (id: number) => {
      deleteMutation({
        variables: { where: { id } }
      });
    },
    [deleteMutation]
  );

  return {
    deleteTask,
    deleting,
    deleteError
  };
}
