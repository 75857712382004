import { PieChart, Pie, Cell } from 'recharts';

const COLORS = ['#0088FE', 'gray'];

type ProgressChartProps = {
  progress: number;
  width?: number;
  lineWidth?: number;
};

export default function ProgressChart({ progress, width = 150, lineWidth = 30 }: ProgressChartProps) {
  const data = [
    { name: 'Progress', value: progress },
    { name: 'Remaning', value: 1 - progress }
  ];
  return (
    <div className="progress-chart text-center">
      <PieChart width={width} height={width}>
        <Pie
          data={data}
          cx={width / 2 - 5}
          cy={width / 2 - 5}
          innerRadius={(width - lineWidth - 10) / 2}
          outerRadius={(width - 10) / 2}
          fill="#8884d8"
          paddingAngle={2}
          dataKey="value"
          startAngle={450}
          endAngle={90}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${entry.name}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
}
